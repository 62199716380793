<template>
    <div v-if="isOpen" class="postJobWrapper">
        <div class="exit_zone" @click="togglePostJob"></div>
        <div>
            <div class="form_section">
                <span id="job">{{ $t("Job Information") }}</span>
                <form action="POST" @submit.prevent="onSubmit" ref="form1">
                    <!-- ... your form content for section 1 ... -->
                    <div class="input_group">
                        <input type="text" id="job_title" name="job_title" v-model="job.title" @focus="isInputFocused = true" required /><label
                            class="required floating_label"
                            :class="{ active: job.title }"
                            for="job_title"
                        >
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/job_icon.svg" alt="" />
                            {{ $t("Job Title<") }}
                        </label>
                        <span v-if="requiredFields.job_title" class="err_msg">{{ requiredFields.job_title }} </span>
                    </div>
                    <div class="input_group2">
                        <div class="input_group select">
                            <label class="required select_label" for="job_seniority"><img loading="lazy" decoding="async" src="../assets/Images/icons/seniority_icon.svg" alt="" /></label>
                            <VueMultiselect
                                :placeholder="$t('Select job seniority')"
                                class="skills"
                                v-model="job.seniority"
                                :options="seniorities"
                                :taggable="true"
                                id="job_seniority"
                                :allowEmpty="false"
                            />
                            <span v-if="requiredFields.job_seniority" class="err_msg">{{ requiredFields.job_seniority }}</span>
                            <!-- <input
                    type="text"
                    id="job_skills"
                    style="display: none"
                    name="job_skills"
                    v-model="job_skills"
                    required
                  /> -->
                            <!--trackBy="skill" label="skill"-->
                        </div>
                        <div class="input_group select" style="overflow-x: show">
                            <label class="required select_label" for="job_department"><img loading="lazy" decoding="async" src="../assets/Images/icons/department_icon.svg" alt="" /></label>
                            <VueMultiselect
                                :placeholder="$t('Select department')"
                                class="skills"
                                v-model="job.department"
                                :options="departments"
                                :taggable="true"
                                id="job_department"
                                :allowEmpty="false"
                            />
                            <span v-if="requiredFields.job_department" class="err_msg">{{ requiredFields.job_department }}</span>
                            <!-- <input
                    type="text"
                    id="job_skills"
                    style="display: none"
                    name="job_skills"
                    v-model="job_skills"
                    required
                  /> -->
                            <!--trackBy="skill" label="skill"-->
                        </div>
                    </div>
                    <div class="input_group2">
                        <div class="input_group select">
                            <label class="required select_label" for="job_skills"><img loading="lazy" decoding="async" src="../assets/Images/icons/skills_icon.svg" alt="" /></label>
                            <VueMultiselect
                                :placeholder="$t('Select skills')"
                                class="skills"
                                v-model="job.skills"
                                :options="skills"
                                :multiple="true"
                                :hideSelected="true"
                                id="job_skills"
                                :allowEmpty="false"
                                :multipleLabel="() => 'Skills'"
                            />
                            <span v-if="requiredFields.job_skills" class="err_msg">{{ requiredFields.job_skills }}</span>
                            <!-- <input
                    type="text"
                    id="job_skills"
                    style="display: none"
                    name="job_skills"
                    v-model="job_skills"
                    required
                  /> -->
                            <!--trackBy="skill" label="skill"-->
                        </div>
                        <div class="input_group select">
                            <label class="required select_label" for="job_compensation"><img loading="lazy" decoding="async" src="../assets/Images/icons/compensation.svg" alt="" /></label>
                            <VueMultiselect
                                class="skills"
                                :placeholder="$t('Select a compensation')"
                                v-model="job.compensation"
                                :options="compensations"
                                :taggable="true"
                                id="job_compensation"
                                :allowEmpty="false"
                            />
                            <span v-if="requiredFields.job_compensation" class="err_msg">{{ requiredFields.job_compensation }}</span>
                            <!-- <input
                    type="text"
                    id="job_skills"
                    style="display: none"
                    name="job_skills"
                    v-model="job_skills"
                    required
                  /> -->
                            <!--trackBy="skill" label="skill"-->
                        </div>
                    </div>
                    <div class="hide_salary">
                        <input type="checkbox" id="hide_salary" v-model="job.hide_salary" />
                        <label for="hide_salary">{{ $t("Don't show Salary?") }}</label>
                        <span>{{ $t("If you prefer, the job opening salary can also be kept private.") }}</span>
                    </div>
                    <div class="input_group">
                        <label class="required" for="job_description"><img loading="lazy" decoding="async" src="../assets/Images/icons/description_icon.svg" alt="" />Job Description</label>
                        <textarea name="job_description" id="job_description" rows="8" v-model="job.description" required></textarea>

                        <span v-if="requiredFields.job_description" class="err_msg">{{ requiredFields.job_description }}</span>
                    </div>
                </form>
            </div>
            <div class="btn_wrapper">
                <button class="previous" @click="togglePostJob">{{ $t("Cancel") }}</button>

                <input class="next" type="submit" value="Post Job" @click="PostJob" />
            </div>
        </div>
    </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { useStore } from "../store/index";
import axios from "axios";
export default {
    name: "PostNewJob",
    components: { VueMultiselect },
    props: {
        isOpen: Boolean,
        togglePostJob: Function,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            job: {
                title: "",
                seniority: "",
                compensation: "",
                department: "",
                skills: [],
                salary: null,
                description: "",
                hide_salary: false,
                company_name: this.Store.company_name,
            },
            requiredFields: {
                job_title: "",
                job_seniority: "",
                job_department: "",
                job_skills: "",
                job_compensation: "",
                job_description: "",
            },
            skills: ["communication", "team work", "Python", "SQL", "Java", "Javascript"],
            seniorities: ["Internship", "Entry Level", "Junior", "Senior", "VP", "Executive"],
            departments: [
                "Executive - CEO, COO, CFO",
                "Finance - Accounting, Budgeting, Payroll",
                "Human Resources - Recruiting, Training, Compensation, Benefits",
                "Sales - Sales Development, Account Management, Customer Success",
                "Marketing - Branding, Advertising, PR, Digital Marketing",
                "Customer Service - Support, Complaint Resolution, Retention",
                "Operations - Manufacturing, Supply Chain, Logistics, Production",
                "Research & Development - Product Development, Quality Assurance, Testing",
                "Information Technology - Systems, Software, Infrastructure, Security",
                "Legal - Contracts, Compliance, Intellectual Property",
                "Administrative - Office Management, Facilities, Reception",
                "Engineering - Product, Software, Systems, Quality",
                "Design - Graphic, User Experience, User Interface, Industrial",
                "Business Development - Strategy, Partnerships, Investor Relations",
                "Project/Program Management - Project Planning, Launching Initiatives",
                "Data/Analytics - Business Intelligence, Data Science, Reporting",
                "Risk Management - Assessing, Monitoring, Mitigating Business Risks",
                "Corporate Communications - Public Relations, Reputation Management",
            ],
            compensations: ["Entry-level: $12 - $16 per hour", "Skilled/Experienced: $17 - $25 per hour", "Manager/Supervisor: $26 - $35 per hour", "Executive/Director: $36 - $50 per hour"],
        };
    },
    methods: {
        async PostJob() {
            try {
                axios
                    .post(`https://server.go-platform.com/jobs/postJob`, this.job, {
                        withCredentials: true,
                    })
                    .then(() => {
                        this.job = {
                            title: "",
                            seniority: "",
                            compensation: "",
                            department: "",
                            skills: [],
                            salary: null,
                            description: "",
                            hide_salary: false,
                        };
                    })
                    .catch((error) => console.log({ error }));
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.postJobWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    > :nth-child(2) {
        background: #fff;
        width: 60%;
        padding: 1rem 2rem;
        border-radius: 20px;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        position: relative;

        @media (min-width: 768px) and (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 767.98px) {
            /* Your CSS styles for smartphones go here */
            width: 80%;
            height: 60%;
        }

        .log_in {
            width: 80%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: absolute;
            bottom: 15%;
            top: 25%;

            form {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 1rem;
                color: #5a5a5d;

                > :first-child {
                    font-weight: 500;
                    font-size: 28px;
                    line-height: 14px;
                }

                /*>:nth-child(5){
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem 0 0;
                }*/
                .captcha {
                    padding: 1rem;

                    > :first-child {
                        > :first-child {
                            width: 100% !important;
                        }
                    }
                }
            }

            .sign_up {
                font-weight: 400;
                font-size: 19.96px;
                line-height: 100%;
                color: #bebebf;
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
            }

            @media (max-width: 767.98px) {
                /* Your CSS styles for smartphones go here */
                gap: 4rem;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */
    }
}

.exit_zone {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.form_section {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;

    > :first-child {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2196f3;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: #e5e5ef;
            margin: 1rem 0;
        }
    }
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;

    > :nth-child(2) {
        width: 100%;
        float: right;
        right: 0;
        border: none;
    }

    .select_label {
        // position: absolute;
        // top: 50%;
        // left: 5%;
        // z-index: 5;
        padding: 0 1% 0 0;
        // transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
    border-radius: 20px;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #05152e;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 25px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: #fff;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: #fff;

        transform: translateY(-150%);
    }

    input,
    textarea {
        border: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 20px;
        width: 100%;
    }

    input {
        height: 3rem;
    }

    textarea {
        resize: none;
    }
}

.input_group2 {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    div {
        width: 100%;
    }

    .skills {
        border-radius: 20px;
    }

    @media (max-width: 767.98px) {
        /* Your CSS styles for smartphones go here */

        flex-direction: column;

        div {
            width: 90%;
        }

        .skills {
            width: 100%;
        }
    }
}

.btn_wrapper {
    height: 100px;
    width: 50%;
    margin: 0 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    bottom: 0%;

    .previous {
        height: 50px;
        width: 150px;
        background-color: #fff;
        color: #2196f3;
        border: 1px solid #2196f3;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            background-color: #2196f3;
            color: #fff;
            border: 1px solid #fff;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }

    .next {
        height: 50px;
        width: 150px;
        background-color: #2196f3;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 20px;
        font-weight: 500;
        font-size: 20px;
        padding: 1% 3%;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #fff;
            color: #2196f3;
            border: 1px solid #2196f3;
            cursor: pointer;
        }

        &:disabled {
            background-color: #fff;
            color: #47505c;
            border: 1px solid #47505c;
            // padding: 2% 3%;
            cursor: not-allowed;
        }
    }
}
</style>
