<template>
    <div class="w-full border mx-auto h-fit py-8 px-4 pl-4 justify-start items-center flex-wrap">
        <div class="h-full flex flex-col justify-start items-start">
            <h1
                class="text-[16px] text-left w-full text-slate-700 font-medium relative pb-2 mb-4 after:content-('') after:absolute after:w-full after:h-px after:bottom-0 after:right-0 after:bg-slate-300"
            >
                Learn
            </h1>
            <a
                :href="btn.path"
                v-for="(btn, index) in ressources"
                :key="index"
                class="group w-full p-2 text-left text-[14px] font-md font-light hover:bg-[rgba(0,0,0,5%)] rounded-md flex flex-row justify-start items-center gap-2"
            >
                <font-awesome-icon :icon="btn.icon" v-if="btn.icon" class="text-sm text-[#2196f3] bg-[rgba(0,0,0,5%)] p-4 w-4 h-4 rounded group-hover:bg-[#fff]" /> {{ btn.name }}
            </a>
        </div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: "ResourcesDropdown",
    data() {
        return {
            ressources: [
                { name: "Case studies", path: "/", icon: "fa-solid fa-laptop-file " },
                { name: "Blogs", path: "/blogs", icon: "fa-solid fa-book-open " },
                { name: "Skill-based hiring hub", path: "/", icon: "fa-solid fa-chalkboard-user " },
                { name: "Talent assessment hub", path: "/Talents", icon: "fa-solid fa-ranking-star " },
            ],
        };
    },
};
</script>

<style lang="scss" scoped></style>
