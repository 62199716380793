<template>
    <component @click="action" :is="as" :disabled="disabled" :class="buttonClass">
        <svg v-if="loading" class="animate-spin absolute h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>

        <component :is="leftIcon" :class="['w-5 h-5 mr-2', loading && 'invisible']"> </component>

        <span :class="[loading && 'invisible']">
            <slot />
        </span>

        <component :is="rightIcon" :class="['w-5 h-5 mr-2', loading && 'invisible']"> </component>
    </component>
</template>

<script>
import { cva } from "class-variance-authority";
export default {
    name: "ButtonComponent",
    props: {
        leftIcon: Function,
        rightIcon: Function,
        action: Function,
        loading: Boolean,
        disabled: Boolean,
        as: {
            type: [String, Object],
            default: "button",
        },
        intent: {
            type: String,
            validator: (val) => ["primary", "secondary", "danger", "text"].includes(val),
            default: "primary",
        },
    },
    data() {
        return {};
    },
    computed: {
        buttonClass() {
            return cva("inline-flex items-center justify-center  text-m rounded min-h-[32px] px-5 py-3 font-semibold ", {
                variants: {
                    intent: {
                        primary: "bg-[#2196f3] text-white hover:bg-[#1976d2]",
                        secondary: "bg-black/5 text-gray-700 hover:bg-black/10",
                        danger: "bg-red-600 text-white hover:bg-red-500",
                        text: "text-gray-700 hover:bg-black/5",
                    },
                    disabled: {
                        true: "!bg-gray-100 !text-gray-400 cursor-not-allowed",
                    },
                },
            })({
                intent: this.intent,
                disabled: this.disabled,
            });
        },
    },
};
</script>

<style></style>
