<template>
    <div class="z-30">
        <DropdownMenu as="div" class="flex-none z-30 relative inline-block text-left">
            <div>
                <MenuButton as="template">
                    <slot />
                </MenuButton>
            </div>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    :class="{
                        'right-0 origin-top-right': align == 'right',
                        'left-0 origin-top-left': align == 'left',
                    }"
                    :style="{ width }"
                    class="absolute mt-2 z-30 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                    <slot name="items" />
                </MenuItems>
            </transition>
        </DropdownMenu>
    </div>
</template>

<script>
import { Menu as DropdownMenu, MenuButton, MenuItems } from "@headlessui/vue";
//import { ChevronDownIcon } from '@heroicons/vue/20/solid'
/*import ArchiveIcon from './archive-icon.vue'
import DuplicateIcon from './duplicate-icon.vue'
import MoveIcon from './move-icon.vue'
import EditIcon from './edit-icon.vue'
import DeleteIcon from './delete-icon.vue'*/

export default {
    name: "DropdownComponent",
    components: {
        DropdownMenu,
        MenuButton,
        MenuItems,
        // ChevronDownIcon
    },
    props: {
        align: {
            type: String,
            validator: (value) => ["left", "right"].includes(value),
        },
        width: {
            type: String,
            default: "10rem",
        },
    },
};
</script>
