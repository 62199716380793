<template>
    <div class="relative w-full mt-[5px]">
        <button class="w-full px-3 py-2 text-left text-gray-500 border border-gray-300 rounded-t-md focus:outline-none pl-70 flex justify-between items-center" @click="toggleOptions">
            {{ value ? value : label }}
            <div class="duration-500" :style="{ transform: showOptions ? 'rotate(180deg)' : 'rotate(0deg)' }">
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </div>
        </button>
        <ul v-if="showOptions" class="w-full absolute top-[100%] divide-y divide-gray-100 z-10 bg-[#fff] border border-gray-300 rounded-b-md max-h-[200px] overflow-y-scroll">
            <li v-for="(option, index) in options" :key="index" class="py-2 px-3 hover:bg-[#2371b631] duration-500" @click="selectOption(option)">
                {{ option }}
            </li>
        </ul>
        <p v-if="errorMgs" class="absolute top-[100%] text-red-500 text-xs mt-1">
            {{ errorMgs }}
        </p>
    </div>
</template>

<script>
export default {
    name: "SelectComponent2",
    props: {
        label: String,
        value: [String, Number],
        setOption: Function,
        options: Array,
        errorMgs: String,
        attribute: String,
        answerIndx: Number,
    },
    data: () => ({
        showOptions: false,
    }),
    methods: {
        toggleOptions() {
            this.showOptions = !this.showOptions;
        },
        getOptionName(id) {
            const option = this.options.find((option) => option._id === id);
            return option ? option.name : "";
        },
        selectOption(option) {
            this.setOption(this.answerIndx, option);
            this.toggleOptions();
        },
    },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
