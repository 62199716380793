<template>
    <div :class="['!px-1 !text-left', label ? '!pt-5 !pb-1' : '!py-1']">
        <div v-if="label" class="!mb-1.5 !px-2 !text-[13px] !font-bold !tracking-tight !uppercase">
            {{ label }}
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: "DropdownItemGroup",
    props: {
        label: String,
    },
};
</script>

<style scoped>
div {
    display: block !important;
    /* or other styles you need */
    align-items: unset !important;
    gap: unset !important;
}
</style>
