<template>
    <div class="w-full border mx-auto h-fit py-8 px-20 pl-4 grid grid-cols-3 gap-8 justify-start items-center flex-wrap">
        <div class="h-full flex flex-col justify-start items-start">
            <h1
                class="text-[16px] text-left w-full text-slate-700 font-medium relative pb-2 mb-4 after:content-('') after:absolute after:w-full after:h-px after:bottom-0 after:right-0 after:bg-slate-300"
            >
                Product information
            </h1>
            <a
                :href="btn.path"
                v-for="(btn, index) in information"
                :key="index"
                class="group w-full p-2 text-left text-[14px] font-md font-light hover:bg-[rgba(0,0,0,5%)] rounded-md flex flex-row justify-start items-center gap-2"
            >
                <font-awesome-icon :icon="btn.icon" v-if="btn.icon" class="text-sm text-[#2196f3] bg-[rgba(0,0,0,5%)] p-4 w-4 h-4 rounded group-hover:bg-[#fff]" /> {{ btn.name }}
            </a>
        </div>
        <div class="h-full flex flex-col justify-start items-start">
            <h1
                class="text-[16px] text-left w-full text-slate-700 font-medium relative pb-2 mb-4 after:content-('') after:absolute after:w-full after:h-px after:bottom-0 after:right-0 after:bg-slate-300"
            >
                Test library
            </h1>
            <a
                :href="btn.path"
                v-for="(btn, index) in library"
                :key="index"
                class="group w-full p-2 text-left text-[14px] font-md font-light hover:bg-[rgba(0,0,0,5%)] rounded-md flex flex-row justify-start items-center gap-2"
            >
                <font-awesome-icon :icon="btn.icon" v-if="btn.icon" class="text-sm text-[#2196f3] bg-[rgba(0,0,0,5%)] p-4 w-4 h-4 rounded group-hover:bg-[#fff]" /> {{ btn.name }}
            </a>
        </div>
        <div class="h-full flex flex-col justify-start items-start">
            <h1
                class="text-[16px] text-left w-full text-slate-700 font-medium relative pb-2 mb-4 after:content-('') after:absolute after:w-full after:h-px after:bottom-0 after:right-0 after:bg-slate-300"
            >
                Solutions
            </h1>
            <a
                :href="btn.path"
                v-for="(btn, index) in solutions"
                :key="index"
                class="group w-full p-2 text-left text-[14px] font-md font-light hover:bg-[rgba(0,0,0,5%)] rounded-md flex flex-row justify-start items-center gap-2"
            >
                <font-awesome-icon :icon="btn.icon" v-if="btn.icon" class="text-sm text-[#2196f3] bg-[rgba(0,0,0,5%)] p-4 w-4 h-4 rounded group-hover:bg-[#fff]" /> {{ btn.name }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductDropdown",
    data() {
        return {
            solutions: [
                // { name: "Software companies", path: "/assessments-library/Software-companies", icon: "fa-regular fa-building " },
                { name: "BPO and outsourcing", path: "/BPO-and-outsourcing", icon: "fa-solid fa-globe " },
                { name: "IT services", path: "/assessments-library/IT-services", icon: "fa-solid fa-headset " },
            ],
            information: [
                { name: "Product tour", path: "/Tour", icon: "fa-solid fa-route" },
                { name: "science", path: "/SciencePage", icon: "fa-solid fa-flask-vial " },
                // { name: "ATS integrations", path: "/assessments-library/ATS-integrations", icon: " fa-solid fa-link" },
            ],
            library: [
                { name: "Programming", path: "/assessments-library/Software Development", icon: " fa-solid fa-code" },
                { name: "Sales and Marketing", path: "/assessments-library/Sales and Marketing", icon: "fa-regular fa-face-smile " },
                { name: "Finance and Accounting", path: "/assessments-library/Finance and Accounting", icon: " fa-solid fa-magnifying-glass-chart" },
                // { name: "languages", path: "/assessments-library/languages", icon: "fa-solid fa-language " },
            ],
        };
    },
};
</script>

<style lang="scss" scoped></style>
