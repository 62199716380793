<template>
    <div>
        <!-- Label -->
        <label :for="id" class="block w-[200px] h-[18px] text-[16px] text-left font-manrope text-lg font-medium text-[#4C535F] mb-[4px]">
            {{ label }}
        </label>

        <!-- Input -->
        <input
            :id="id"
            :placeholder="placeholder"
            :class="['w-full block mt-4 px-4 py-3 rounded-lg', 'text-[14px] leading-[15.74px] font-manrope font-medium text-[#8D98AA]', 'border-[#E0E4EC] bg-[#E0E4EC]']"
            :style="{
                height: inputHeight,
                width: inputWidth,
            }"
        />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "Enter text",
        },
        id: {
            type: String,
            default: "input-field",
        },
        inputWidth: {
            type: String,
            default: "100%",
        },
        inputHeight: {
            type: String,
            default: "100%",
        },
    },
};
</script>

<style scoped>
/* Custom font */
.font-manrope {
    font-family: "Manrope", sans-serif;
}
</style>
