<template>
    <div class="relative pb-[5%]">
        <div class="mt-[-6%] flex items-center">
            <div
                class="w-full py-[5%] px-[5%] mx-[3%] md:mx-[5%] lg:mx-[10%] rounded-md flex flex-col items-center"
                style="background: #312f92; background: linear-gradient(90deg, #3db5e6 0%, #312f92 100%)"
            >
                <h1 class="text-[#fff] font-[700] text-[20px] md:text-[38px] md:w-[80%]">Backed by GO PLATFORM 30 Days Guarantee</h1>
                <h1 class="text-[#fff] text-[16px] md:text-[24px] md:w-[80%]">
                    If you are not Satisfied with your hire within the first 30 days, you pay nothing. we will find you another talent at no additional cost
                </h1>
            </div>
        </div>
        <div class="absolute top-0 left-0 -z-10">
            <svg width="354" height="1139" viewBox="0 0 354 1139" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M351.5 569.214C351.5 882.334 111.396 1135.93 -184.5 1135.93C-480.396 1135.93 -720.5 882.334 -720.5 569.214C-720.5 256.094 -480.396 2.5 -184.5 2.5C111.396 2.5 351.5 256.094 351.5 569.214Z"
                    stroke="#F5F8FF"
                    stroke-width="5"
                />
            </svg>
        </div>

        <hr class="h-[5px] bg-[#F5F8FF] my-[8%]" />
        <div class="flex flex-col md:flex-row justify-between items-center px-[3%] md:px-[5%] lg:px-[10%]">
            <div class="w-full md:w-[40%] text-center md:text-left">
                <h1 class="uppercase font-[700] text-[36px] md:text-[46px] text-[#343637]">
                    Recruite the Top 1% of
                    <span class="relative w-[fit-content] inline-block">
                        <span class="absolute top-0 left-0 w-full h-full bg-[#E0DBFF] -rotate-[6deg] -z-10"></span>
                        <span class="relative z-5">African</span>
                    </span>
                    Talent.
                </h1>
                <p class="text-[16px] text-[#343637] mt-[5%] md:mt-0">
                    Our collaborative approach guarantees that we fully grasp your company’s unique needs and concerns to find a perfect fit – in both skills and culture
                </p>
                <hr class="h-[5px] bg-[#F5F8FF] my-[5%]" />
                <div class="flex flex-col gap-3">
                    <h3 class="text-[16px] text-[#343637] flex items-center">
                        <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                                fill="#3361FF"
                            />
                        </svg>
                        Tailored Talent Matching
                    </h3>
                    <h3 class="text-[16px] text-[#343637] flex items-center">
                        <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                                fill="#3361FF"
                            />
                        </svg>
                        Competitive Costing
                    </h3>
                    <h3 class="text-[16px] text-[#343637] flex items-center">
                        <svg class="mr-5" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411618 7.6004 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48946 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2156 19 11.3789 19 9.5C19 6.98044 17.9991 4.56408 16.2175 2.78248C14.4359 1.00089 12.0196 0 9.5 0ZM8.14286 13.2932L4.75 9.90036L5.82893 8.82143L8.14286 11.1354L13.1711 6.10714L14.2541 7.18336L8.14286 13.2932Z"
                                fill="#3361FF"
                            />
                        </svg>
                        Hassle-free Hiring
                    </h3>
                </div>
            </div>
            <div class="flex flex-col lg:flex-row gap-8 w-full md:w-[45%] justify-between mt-[3%] md:mt-0">
                <carousel :breakpoints="breakpoints" class="w-full">
                    <slide v-for="(talent, index) in talents" :key="index">
                        <div class="px-6 py-4 rounded-[10px] border mx-2">
                            <div class="flex items-center my-4">
                                <div class="mr-[5%] bg-[#F5F8FF] rounded-full h-[50px] w-[50px] flex items-center justify-center">
                                    <font-awesome-icon :icon="['fas', 'user']" />
                                </div>
                                <div class="text-left ml-5">
                                    <h1 class="text-[#343637] text-[12px] font-[700]">{{ talent.name }}</h1>
                                    <h1 class="text-[#343637] text-[12px] font-[500]">{{ talent.role }}</h1>
                                    <p class="text-[10px] text-[#fff] bg-[#3361FF] rounded-xl p-2">{{ talent.experience }}</p>
                                </div>
                            </div>
                            <div class="flex flex-wrap gap-2 my-4">
                                <span class="text-[#343637] bg-[#E0DBFF] text-[12px] rounded-xl py-2 px-4" v-for="(skill, index) in talent.skills" :key="index">{{ skill }}</span>
                            </div>
                            <p class="text-[12px] text-[#343637]">Design and implement machine learning models for predictive analytics.</p>
                            <div class="flex justify-between items-center my-4">
                                <router-link to="/request-service" class="text-[#fff] bg-[#3361FF] rounded-xl px-4 py-2 border border-[#3361FF] hover:bg-transparent hover:text-[#3361FF] duration-300"
                                    >Hire Now</router-link
                                >
                                <p class="text-[12px] text-[#343637] font-[600]">750$ /Month</p>
                            </div>
                        </div>
                    </slide>
                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
    name: "RecruitmentComponent",
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            talents: [
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
                {
                    name: "Jennifer Okafor",
                    role: "ML Engineer",
                    experience: "4 Years Experience",
                    skills: ["Python", "Tensorflow", "Keras", "Pytorch"],
                },
            ],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1.5,
                    snapAlign: "start",
                },
                1350: {
                    itemsToShow: 2,
                    snapAlign: "start",
                },
            },
        };
    },
};
</script>
