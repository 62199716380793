<template>
    <MenuItem v-slot="{ active }">
        <button @click="action" :class="[active ? '!bg-[#2196f3] !text-white' : '!text-gray-900', '!group !flex !flex-col !w-full !font-extralight !rounded-md !px-2 !py-2 !text-left !text-sm']">
            <span class="font-extralight flex justify-between w-full items-center">
                <slot />
            </span>
            <span v-if="description" class="mt-0.5 text-xs text-gray-500 group-hover:text-white">{{ description }}</span>
        </button>
    </MenuItem>
</template>
<script>
import { MenuItem } from "@headlessui/vue";
export default {
    name: "DropdownItem",
    components: {
        MenuItem,
    },
    props: {
        description: String,
        action: Function,
    },
};
</script>
