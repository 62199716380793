<template>
    <div class="tabs">
        <ul class="tabs__header">
            <li v-for="title in tabTitles" :key="title" :class="{ selected: title == selectedTitle }" @click="selectedTitle = title">
                <span>{{ title }}</span>
            </li>
            <button class="AddProject">
                <font-awesome-icon class="faIcon" :icon="['fas', 'plus']" />
            </button>
        </ul>
        <slot></slot>
    </div>
</template>

<script>
import { ref, provide } from "vue";
export default {
    name: "ProjectList",
    setup(props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props.title));
        const selectedTitle = ref(tabTitles.value[0]);
        provide("selectedTitle", selectedTitle);
        return { selectedTitle, tabTitles };
    },
};
</script>

<style lang="scss" scoped>
.tabs {
    width: 100%;
    margin: 0 auto;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
}
.tabs__header {
    margin-bottom: -1px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
}
.AddProject {
    background: none;
    border: none;
    font-size: 18px;
    width: 2rem;
    margin: 0 0.5rem;
}
.tabs__header li {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.4s all ease-out;
    border-radius: 10px 10px 0 0;
    background: #f0f0f0;
}
.tabs__header li:hover {
    background-color: #ddd;
}
.tabs__header li.selected {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top: 3px solid #ddd;
    border-bottom: 1px solid #fff;
    border-radius: 10px 10px 0 0;
}
</style>
