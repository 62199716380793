<template>
    <div class="relative mt-[5px] w-full">
        <input
            class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 pl-70"
            :type="type"
            :name="name"
            :value="value"
            @input="(e) => setInputs(e.target.value, answerIndx)"
            @focus="handleFocus"
            @blur="handleBlur"
        />
        <label
            :class="{
                'absolute top-[-20%]': isFocused || value,
                'absolute top-2': !(isFocused || value),
                'text-indigo-500 text-xs': isFocused || value,
                'text-gray-500 text-base': !(isFocused || value),
            }"
            class="left-0 mx-2 transition-all duration-300 ease-in-out bg-white"
            :for="name"
        >
            {{ label }}
        </label>
        <template v-if="type === 'password'">
            <img v-if="!showEye" class="absolute top-[25%] right-2 cursor-pointer" src="/assets/icons/closedeye.svg" @click="switchShowPAss" />
            <img v-else class="absolute top-[25%] right-2 cursor-pointer" src="/assets/icons/openeye.svg" @click="switchShowPAss" />
        </template>
        <p v-if="errorMgs" class="absolute top-[100%] text-red-500 text-xs mt-1">
            {{ errorMgs }}
        </p>
    </div>
</template>

<script>
export default {
    name: "FloatingLabel",
    props: {
        label: String,
        name: String,
        type: String,
        value: [String, Number],
        errorMgs: String,
        switchShowPAss: Function,
        showEye: Boolean,
        answerIndx: Number,
        setInputs: Function,
    },
    data() {
        return {
            isFocused: false,
        };
    },
    methods: {
        handleFocus() {
            this.isFocused = true;
        },
        handleBlur() {
            this.isFocused = false;
        },
    },
};
</script>

<style scoped>
/* You can add additional styles here if needed */
</style>
