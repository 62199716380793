<template>
    <div class="w-full">
        <div class="w-full flex flex-col md:flex-row justify-between items-center">
            <div class="w-[20%]">
                <input
                    placeholder="Search"
                    class="font-light !text-sm !py-2 !px-3 !border !border-[1.5px] !rounded-md w-full !border-[rgb(228, 228, 231)] focus:outline-none focus:ring-2 focus:ring-blue-500 h-[50px] searchInput"
                    type="text"
                    id="searchInput"
                    name="searchInput"
                    v-model="searchPayload"
                    required
                />
            </div>

            <div class="w-[30%]">
                <div class="grid grid-cols-3">
                    <button
                        @click="showAll"
                        class="statusCard border-[2px] border-r-0 border-[rgb(228, 228, 231)] rounded-l-[10px]"
                        :class="this.selectedStatus.status === 'All' ? 'active' : ''"
                        id="statusCard"
                    >
                        All ({{ this.projects.length }})
                    </button>
                    <button @click="showActive" :class="this.selectedStatus.status === 'Active' ? 'active' : ''" class="statusCard border-[2px] border-[rgb(228, 228, 231)]" id="statusCard">
                        Active ({{ this.projects.filter((project) => project.project_status == "Active").length }})
                    </button>
                    <button
                        @click="showArchived"
                        :class="this.selectedStatus.status === 'Archived' ? 'active' : ''"
                        class="statusCard border-[2px] border-l-0 border-[rgb(228, 228, 231)] rounded-r-[10px]"
                        id="statusCard"
                    >
                        Archived ({{ this.projects.filter((project) => project.project_status == "Archived").length }})
                    </button>
                </div>
                <!-- <Listbox v-model="selectedStatus">
                    <div class="relative z-[7]">
                        <ListboxButton
                            class="relative w-full cursor-default !border !border-[1.5px] !border-[rgb(228, 228, 231)] rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                        >
                            <span class="block truncate">{{ selectedStatus?.status }}</span>
                            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                <ListboxOption v-slot="{ active, selected }" v-for="option in types" :key="option?.status" :value="option" as="template">
                                    <li :class="[active ? 'bg-[#d5efff] text-[#2196f3]' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                                        <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option?.status }}</span>
                                        <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-[#2196f3]">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>
                    </div>
                </Listbox> -->
            </div>
        </div>

        <div class="flex flex-col justify-between w-full rounded" style="background-color: #fff" id="table_body">
            <div class="sm:-mx-6">
                <div class="inline-block min-w-full sm:px-6">
                    <div class="">
                        <div class="grid lg:hidden grid-cols-[10fr_1fr] grid-rows-2 border-b border-gray-300 gap-3" v-for="(project, index) in this.myPojects" :key="index">
                            <div @click="navigateToDashBoard(project)" class="w-full text-base font-light text-slate-700 flex flex-row justify-between row-start-1 row-end-2 col-start-1 col-end-2">
                                <span class="font-bold">{{ project.name }}</span>
                                <span>{{ project.min_score != 0 ? project.min_score : project.recommanded_score }}%</span>
                            </div>
                            <div @click="navigateToDashBoard(project)" class="flex flex-row justify-start items-center gap-5 text-base font-light text-slate-700 row-start-2 col-start-1 col-end-2">
                                <span>{{ getDate(project.createdAt) }}</span>
                                <span class="">{{ project.invitationsNbr }} {{ project.invitationsNbr > 1 ? "Candidates" : "Candidate" }}</span>
                            </div>
                            <button
                                @click.stop
                                @click="
                                    () => {
                                        project.showMenu = !project.showMenu;
                                    }
                                "
                                style="cursor: pointer"
                                class="col-start-2 row-start-1 row-span-2"
                            >
                                <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                            </button>
                        </div>
                        <div class="min-h-[fit-content] relative">
                            <table class="min-w-full text-sm">
                                <thead class="sticky top-0 text-gray-700 bg-[#FBFBFB]" style="text-transform: uppercase">
                                    <tr class="border-b transition-colors hover:bg-muted/50">
                                        <th scope="col" class="text-[#1b2559] h-14 px-4 text-left font-medium">Project</th>
                                        <th scope="col" class="text-[#1b2559] h-14 px-4 text-center font-medium">Date Created</th>
                                        <!-- <th scope="col" class="px-6 py-3">Job Title</th> -->
                                        <th scope="col" class="text-[#1b2559] h-14 px-4 text-center font-medium">Candidates</th>
                                        <th s scope="col" class="text-[#1b2559] h-14 px-4 align-middle font-medium">Progress</th>
                                        <th scope="col" class="text-[#1b2559] h-14 px-4 align-middle font-medium">Success Score</th>
                                        <th scope="col" class="text-[#1b2559] h-14 px-4 align-middle font-medium">Actions</th>
                                    </tr>
                                </thead>
                                <tbody class="max-h-[550px] overflow-y-scroll">
                                    <tr class="border-b-[1px] border-[#e5e7eb] hover:bg-[#2371b631] text-gray-700 duration-300" v-for="(project, index) in this.myPojects" :key="index">
                                        <td class="p-3 align-middle" @click="navigateToDashBoard(project)">{{ project.name }}</td>
                                        <td class="p-3 align-middle text-center" @click="navigateToDashBoard(project)">
                                            {{ getDate(project.createdAt) }}
                                        </td>
                                        <!-- <td class="whitespace-nowrap px-6 py-4 text-[#0F172A]">{{ project.jobTitle }}</td> -->
                                        <td class="p-3 align-middle text-center" @click="navigateToDashBoard(project)">
                                            <!-- {{ project.seniority }} -->
                                            <!-- invitationsNbr: 8, passedCandidatesNbr: 1 -->
                                            {{ project.invitationsNbr }}
                                        </td>
                                        <td class="p-3" @click="navigateToDashBoard(project)">
                                            <!-- <Popper :content="$t('project name tooltip')" placement="left" :hover="true" class="w-full"> -->
                                            <div
                                                class="h-[25px] w-full flex rounded relative"
                                                v-if="project.invitationsNbr"
                                                @mouseenter="() => (project.showInv = !project.showInv)"
                                                @mouseleave="() => (project.showInv = !project.showInv)"
                                            >
                                                <div class="absolute top-0 w-[max-content] z-[10] p-2 right-[105%] popup" v-if="project.showInv" style="transform: translateY(-20%)">
                                                    <div class="flex justify-between items-center">
                                                        Completed: {{ project.passedCandidatesNbr }} |
                                                        <span class="text-right ml-4 opacity-60"> {{ parseInt((project.passedCandidatesNbr * 100) / project.invitationsNbr) }}% </span>
                                                    </div>
                                                    <!-- <br /> -->
                                                    <div class="flex justify-between items-center">
                                                        Not Started: {{ project.invitationsNbr - project.passedCandidatesNbr }} |
                                                        <span class="text-right ml-4 opacity-60"> {{ 100 - parseInt((project.passedCandidatesNbr * 100) / project.invitationsNbr) }}% </span>
                                                    </div>
                                                    <!-- <div class="triangle-right"></div> -->
                                                </div>
                                                <div
                                                    class="w-[50%] h-full relative flex items-center justify-center text-[#fff]"
                                                    style="background: rgb(123, 42, 255); background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%)"
                                                    :style="{ width: `${parseInt(project.passedCandidatesNbr * 100) / project.invitationsNbr}%` }"
                                                >
                                                    <!-- @mouseenter="() => (project.showCom = !project.showCom)"
                                                    @mouseleave="() => (project.showCom = !project.showCom)" -->
                                                    <!-- <div class="absolute top-0 right-[110%] p-2 bg-[#C8D7DD] text-sm" v-if="project.showCom" style="transform: translateY(-20%)">
                                                        Completed: {{ project.passedCandidatesNbr }}
                                                        <div class="triangle-right"></div>
                                                    </div> -->
                                                    {{ project.passedCandidatesNbr > 0 ? project.passedCandidatesNbr : "" }}
                                                </div>
                                                <div
                                                    class="h-full relative flex items-center justify-center"
                                                    style="background-color: #ededed"
                                                    :style="{ width: `${100 - parseInt(project.passedCandidatesNbr * 100) / project.invitationsNbr}%` }"
                                                >
                                                    <!-- <div class="absolute top-0 p-2 right-[110%] bg-[#C8D7DD] text-sm" v-if="project.showInv" style="transform: translateY(-20%)">
                                                        Not Started: {{ project.invitationsNbr - project.passedCandidatesNbr }}
                                                        <div class="triangle-right"></div>
                                                    </div> -->
                                                    {{ project.invitationsNbr - project.passedCandidatesNbr > 0 ? project.invitationsNbr - project.passedCandidatesNbr : "" }}
                                                </div>
                                            </div>
                                            <!-- </Popper> -->
                                        </td>
                                        <td class="text-center p-3 align-middle" @click="navigateToDashBoard(project)">
                                            {{ project.min_score != 0 ? project.min_score : project.recommanded_score }}%
                                        </td>
                                        <td class="text-center p-3 align-middle" style="position: relative">
                                            <button
                                                @click.stop
                                                @click="
                                                    () => {
                                                        project.showMenu = !project.showMenu;
                                                    }
                                                "
                                                style="cursor: pointer"
                                                class="menuBtn"
                                            >
                                                <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                                            </button>
                                            <div class="absolute z-[5]" style="left: 50%">
                                                <ProjectMenu
                                                    :showDetails="project.showMenu"
                                                    :editable="project.invitationsNbr > 0 ? false : true"
                                                    :toggleMenu="
                                                        () => {
                                                            project.showMenu = !project.showMenu;
                                                        }
                                                    "
                                                    :thisProject="project"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="this.myPojects.length === 0" class="border-b-[1px] border-[#e5e7eb] hover:bg-[#2371b631] text-gray-700 duration-300">
                                        <td class="p-3 align-middle" @click="navigateToDummyBoard">Dummy Project</td>
                                        <td class="p-3 align-middle text-center" @click="navigateToDummyBoard">2024 - 10 - 21</td>
                                        <!-- <td class="whitespace-nowrap px-6 py-4 text-[#0F172A]">{{ project.jobTitle }}</td> -->
                                        <td class="p-3 align-middle text-center" @click="navigateToDummyBoard">
                                            <!-- {{ project.seniority }} -->
                                            <!-- invitationsNbr: 8, passedCandidatesNbr: 1 -->
                                            4
                                        </td>
                                        <td class="p-3" @click="navigateToDummyBoard">
                                            <!-- <Popper :content="$t('project name tooltip')" placement="left" :hover="true" class="w-full"> -->
                                            <div class="h-[25px] w-full flex rounded relative" @mouseenter="() => (ShowDummyInv = !ShowDummyInv)" @mouseleave="() => (ShowDummyInv = !ShowDummyInv)">
                                                <div class="absolute top-0 w-[max-content] z-[10] p-2 right-[105%] popup" v-if="ShowDummyInv" style="transform: translateY(-20%)">
                                                    <div class="flex justify-between items-center">
                                                        Completed: 3 |
                                                        <span class="text-right ml-4 opacity-60"> 75% </span>
                                                    </div>
                                                    <!-- <br /> -->
                                                    <div class="flex justify-between items-center">
                                                        Not Started: 1 |
                                                        <span class="text-right ml-4 opacity-60"> 25% </span>
                                                    </div>
                                                    <!-- <div class="triangle-right"></div> -->
                                                </div>
                                                <div
                                                    class="w-[50%] h-full relative flex items-center justify-center text-[#fff]"
                                                    style="background: rgb(123, 42, 255); background: linear-gradient(90deg, rgba(123, 42, 255, 1) 0%, rgba(0, 174, 240, 1) 100%)"
                                                    :style="{ width: `75%` }"
                                                >
                                                    <!-- @mouseenter="() => (project.showCom = !project.showCom)"
                                                    @mouseleave="() => (project.showCom = !project.showCom)" -->
                                                    <!-- <div class="absolute top-0 right-[110%] p-2 bg-[#C8D7DD] text-sm" v-if="project.showCom" style="transform: translateY(-20%)">
                                                        Completed: {{ project.passedCandidatesNbr }}
                                                        <div class="triangle-right"></div>
                                                    </div> -->
                                                    3
                                                </div>
                                                <div class="h-full relative flex items-center justify-center" style="background-color: #ededed" :style="{ width: `25%` }">
                                                    <!-- <div class="absolute top-0 p-2 right-[110%] bg-[#C8D7DD] text-sm" v-if="project.showInv" style="transform: translateY(-20%)">
                                                        Not Started: {{ project.invitationsNbr - project.passedCandidatesNbr }}
                                                        <div class="triangle-right"></div>
                                                    </div> -->
                                                    1
                                                </div>
                                            </div>
                                            <!-- </Popper> -->
                                        </td>
                                        <td class="text-center p-3 align-middle" @click="navigateToDummyBoard">50%</td>
                                        <td class="text-center p-3 align-middle" style="position: relative">
                                            <button @click.stop style="cursor: pointer" class="menuBtn">
                                                <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                                            </button>
                                            <div class="absolute z-[5]" style="left: 50%">
                                                <!-- <ProjectMenu
                                                    :showDetails="project.showMenu"
                                                    :editable="project.invitationsNbr > 0 ? false : true"
                                                    :toggleMenu="
                                                        () => {
                                                            project.showMenu = !project.showMenu;
                                                        }
                                                    "
                                                    :thisProject="project"
                                                /> -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="this.myPojects.length > 0" class="w-full flex flex-row items-center flex-nowrap gap-9 lg:pr-3 justify-between lg:justify-end mt-8 mb-[40px]">
                <div class="lg:w-[10%]">
                    <Listbox v-model="selectedOption">
                        <div class="relative">
                            <ListboxButton
                                class="relative w-full cursor-default !border !border-[1.5px] !border-[rgb(228, 228, 231)] rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                            >
                                <span class="block truncate">{{ selectedOption?.number }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </ListboxButton>

                            <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    <ListboxOption v-slot="{ active, selected }" v-for="option in options" :key="option?.number" :value="option" as="template">
                                        <li @click="selectOption(option)" :class="[active ? 'bg-[#d5efff] text-[#2196f3]' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-10 pr-4']">
                                            <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option?.number }}</span>
                                            <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-[#2196f3]">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ListboxOption>
                                </ListboxOptions>
                            </transition>
                        </div>
                    </Listbox>
                </div>
                <div class="flex items-center">
                    <span class="text-s text-[rgb(121,121,121)]">{{ `${this.page} / ${this.nbrOfPages}` }}</span>
                </div>
                <div class="w-[4%] flex justify-between text-[rgb(121,121,121)]">
                    <button @click="previousPage"><font-awesome-icon :icon="['fas', 'chevron-left']" id="slide1" /></button>
                    <button @click="nextPage"><font-awesome-icon :icon="['fas', 'chevron-right']" id="slide2" /></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectMenu from "./ProjectMenu.vue";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
export default {
    name: "ProjectsTable",
    components: {
        ProjectMenu,
        Listbox,
        ListboxButton,
        ListboxOptions,
        ListboxOption,
        CheckIcon,
        ChevronUpDownIcon,
    },
    data() {
        return {
            selectedStatus: { status: "All" },
            types: [{ status: "All" }, { status: "Active" }, { status: "Archived" }],
            myPojects: this.projects.slice(0, 10),
            showMenu: false,
            myProject: {},
            searchPayload: "",
            showCom: false,
            showInv: false,
            showNbrPages: false,
            selectedOption: { number: 10 },
            nbrOfPages: null,
            page: 1,
            options: [{ number: 10 }, { number: 15 }, { number: 25 }], // Example options
            ShowDummyInv: false,
        };
    },
    props: {
        projects: {
            type: Array,
            // required: true,
        },
    },
    created() {
        this.myPojects.map((project) => {
            project.showMenu = false;
            project.showCom = false;
            project.showInv = false;
        });
    },
    watch: {
        searchPayload(newSearchValue) {
            // this.allAssessments = this.allAssessments.filter((assessment) => assessment.name.toLowerCase().includes(newSearchValue.toLowerCase()));
            console.log({ newSearchValue });
            this.myPojects = this.projects.filter((project) => project.name.toLowerCase().includes(newSearchValue));
        },
        "selectedStatus.status"(newVal) {
            this.page = 1;
            if (newVal === "All") {
                console.log("----======================ALL PROJECTSSSSSSSSSSSSSSSSS=============--------------");
                this.myPojects = this.projects.slice(0, this.selectedOption.number);
            } else {
                console.log({ SELECTEEEDDDSTATUSNEWVAL: newVal });
                this.myPojects = this.projects.filter((project) => project.project_status == newVal).slice(0, this.selectedOption.number);
            }
        },
        myPojects(newVal) {
            // handler: "calculateNbrOfPages",
            // deep: true,
            console.log({ newVal });
            this.calculateNbrOfPages(newVal);
        },
    },
    methods: {
        navigateToDashBoard(project) {
            this.$router.push({
                path: "/boards",
                query: { id: project._id },
            });
        },
        navigateToDummyBoard() {
            this.$router.push("/dummy-dashboard");
        },
        nextPage() {
            if (this.page < this.nbrOfPages) {
                this.page++;
                if (this.selectedOption.number === 10) {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 10 - 10, this.page * 10);
                    if (this.selectedStatus.status == "Archived") this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(this.page * 10 - 10, this.page * 10);
                    else this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(this.page * 10 - 10, this.page * 10);
                } else if (this.selectedOption.number === 15) {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 15 - 15, this.page * 15);
                    if (this.selectedStatus.status == "Archived") this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(this.page * 15 - 15, this.page * 15);
                    else this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(this.page * 15 - 15, this.page * 15);
                } else {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 25 - 25, this.page * 25);
                    else this.myPojects = this.projects.filter((project) => project.project_status != "Active").slice(this.page * 15 - 15, this.page * 15);
                }
            }
        },
        previousPage() {
            if (this.page > 1) {
                this.page--;
                if (this.selectedOption.number === 10) {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 10 - 10, this.page * 10);
                    if (this.selectedStatus.status == "Archived") this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(this.page * 10 - 10, this.page * 10);
                    else this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(this.page * 10 - 10, this.page * 10);
                } else if (this.selectedOption.number === 15) {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 15 - 15, this.page * 15);
                    if (this.selectedStatus.status == "Archived") this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(this.page * 15 - 15, this.page * 15);
                    else this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(this.page * 15 - 15, this.page * 15);
                } else {
                    if (this.selectedStatus.status == "Active") this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(this.page * 25 - 25, this.page * 25);
                    if (this.selectedStatus.status == "Archived") this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(this.page * 25 - 25, this.page * 25);
                    else this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(this.page * 25 - 25, this.page * 25);
                }
            }
        },
        toggleDropdown() {
            console.log("toggling dropdown");
            this.showNbrPages = !this.showNbrPages;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.page = 1;
            if (option.number === 10) {
                if (this.selectedStatus?.status == "Active") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(0, 10);
                    this.showNbrPages = false;
                }
                if (this.selectedStatus?.status == "Archived") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(0, 10);
                    this.showNbrPages = false;
                } else {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(0, 10);
                    this.showNbrPages = false;
                }

                // this.calculateNbrOfPages(this.myPojects);
            } else if (option.number === 15) {
                if (this.selectedStatus?.status == "Active") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(0, 15);
                    this.showNbrPages = false;
                }
                if (this.selectedStatus?.status == "Archived") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(0, 15);
                    this.showNbrPages = false;
                } else {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(0, 15);
                    this.showNbrPages = false;
                }

                // this.calculateNbrOfPages(this.myPojects);
            } else if (option.number === 25) {
                if (this.selectedStatus?.status == "Active") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active").slice(0, 25);
                    this.showNbrPages = false;
                }
                if (this.selectedStatus?.status == "Archived") {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Archived").slice(0, 25);
                    this.showNbrPages = false;
                } else {
                    this.myPojects = this.projects.filter((project) => project.project_status == "Active" || project.project_status == "Archived").slice(0, 25);
                    this.showNbrPages = false;
                }

                // this.calculateNbrOfPages(this.myPojects);
            }
        },
        toggleShowCom(project) {
            this.showCom = !this.showCom;
            console.log("the project ", project);
            if (project) {
                this.myProject.showCom = !this.myProject.showCom;
                this.myProject = project;
            }
        },
        toggleShowInv() {
            this.showInv = !this.showInv;
        },

        toggleMenu(project) {
            if (project) {
                console.log("the project ", this.myProject);
                console.log("toggling menu");
                this.showMenu = !this.showMenu;
                this.myProject.showMenu = true;
                this.myProject = project;
            }
        },
        showAll() {
            this.selectedStatus.status = "All";
            this.myPojects = this.projects;
        },
        showActive() {
            this.selectedStatus.status = "Active";
            this.myPojects = this.projects.filter((project) => project.project_status == "Active");
        },
        showArchived() {
            this.selectedStatus.status = "Archived";
            this.myPojects = this.projects.filter((project) => project.project_status == "Archived");
        },
        getDate(str) {
            // const parts = str.split("-");
            const date = new Date(str);
            const extractedDate = date.toISOString().split("T")[0];
            // console.log("the date ", extractedDate);
            return extractedDate;
        },
        calculateNbrOfPages(status1) {
            status1 = this.selectedStatus.status;
            let activeProjects = [];
            if (status1 == "All") {
                activeProjects = this.projects;
            }
            if (status1 == "Active") {
                activeProjects = this.projects.filter((project) => project.project_status === "Active");
            }
            if (status1 == "Archived") {
                activeProjects = this.projects.filter((project) => project.project_status === "Archived");
            }
            // const activeProjects = this.projects?.filter((project) => project.project_status === status1);
            const activeProjectsCount = activeProjects?.length;
            console.log("the active projects ", activeProjectsCount);
            if (this.selectedOption.number === 10) {
                console.log("10 test per page");
                this.nbrOfPages = activeProjectsCount % 10 > 0 ? Math.floor(activeProjectsCount / 10) + 1 : activeProjectsCount / 10;
            }
            if (this.selectedOption.number === 15) {
                console.log("15 test per page");
                this.nbrOfPages = activeProjectsCount % 15 > 0 ? Math.floor(activeProjectsCount / 15) + 1 : activeProjectsCount / 15;
            }
            if (this.selectedOption.number === 25) {
                console.log("25 test per page");
                this.nbrOfPages = activeProjectsCount % 25 > 0 ? Math.floor(activeProjectsCount / 25) + 1 : activeProjectsCount / 25;
            }
        },
    },
    mounted() {
        this.selectedStatus = this.types[0];
        this.selectedOption.number = this.options[0].number;
        console.log({ SELECTEDDD: this.selectedOption, OOOOPPPPPPPPPPTIONNNNNNNNNNN: this.options[0].number });
        this.calculateNbrOfPages(this.projects);
    },
};
</script>

<style scoped lang="scss">
.popup {
    background: #2371b6;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    max-width: 300px;
}

.triangle-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    /* Adjust the size as needed */
    border-bottom: 10px solid transparent;
    /* Adjust the size as needed */
    border-left: 10px solid #c8d7dd;
    /* Adjust the size and color as needed */
    position: absolute;
    top: 50%;
    right: -5px;
    /* Adjust to position the triangle precisely */
    transform: translateY(-50%);
}

.statusCard {
    height: 50px;
    background-color: #fff;
    color: #0f172a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
    // border: 0.5px solid #0f172a;
    transition: all 0.3s ease;

    &:hover {
        background-color: #2196f3;
        color: #fff;
    }
}

.seachBox {
    background-color: #fff;

    height: 50px;
    border: 2px solid #ededed;
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;

    &:hover {
        border: 1px solid #2196f3;
    }

    .searchIcon {
        color: #aaaaaa;
    }

    input {
        width: 90%;
        height: 100%;
        background-color: transparent;

        &:focus {
            outline: none;
        }
    }
}

.active {
    background-color: #2196f3;
    color: #fff;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    padding: 0 1px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.custom-style:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    // left: 2px;
    // right: 50px;
    // bottom: 3px;
    top: 14%;
    // transform: translateY(-20%);
    border: 0.5px solid rgba(255, 255, 255, 0.342);
    background-color: #f9fafd;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(24px);
}

.projectRow {
    cursor: pointer;

    &:hover {
        background-color: #2371b631;
    }
}

.menuBtn {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #fff;
    }
}

.selectWrapper {
    height: 40px;
    background-color: #fff;
    transition: all 0.3s ease;
    border: 1px solid #ededed;

    &:hover {
        border: 1px solid #2196f3;
    }

    .selectStatus {
        height: 40px;
        width: 100%;
        background-color: #fff;

        &:focus {
            outline: none;
        }

        option {
            margin: 0.5rem 2rem;

            &:hover {
                color: #fff;
                background-color: #2196f3;
            }
        }
    }
}

.custom-select {
    position: relative;
    // width: 200px;
    border-bottom: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.selected-option {
    padding: 8px;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options li {
    padding: 1px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.options li:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    table {
        display: none;
        //  overflow-x: scroll;
        white-space: nowrap;
    }

    th,
    td {
        min-width: 120px;

        // display: inline-block;
    }
}

#table_body {
    width: 100%;
    max-height: calc(89% - 1.6rem);

    margin: 2.5rem 0;
    border-radius: 0.6rem;
    // border: 1px solid #0f172a1c;
    // border-radius: 5px;
    // overflow-x: scroll;
    //        overflow: overlay;
}

#table_body::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

#table_body::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #0004;
    visibility: hidden;
}

#table_body:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

table {
    width: 100%;
}

thead th {
    top: 0;
    left: 0;
    cursor: pointer;
    text-transform: capitalize;
}

tbody tr {
    // --delay: 0.1s;
    // transition:
    //     0.5s ease-in-out var(--delay),
    //     background-color 0s;
}

tbody tr.hide {
    opacity: 0;
    transform: translateX(100%);
}

tbody tr td,
tbody tr td p,
tbody tr td img {
    transition: 0.2s ease-in-out;
}

tbody tr.hide td,
tbody tr.hide td p {
    padding: 0;
    font: 0 / 0 sans-serif;
    transition: 0.2s ease-in-out 0.5s;
}

@media (max-width: 1000px) {
    td:not(:first-of-type) {
        min-width: 12.1rem;
    }
}

#slide:hover {
    color: #2196f3;
}

#slide1:hover {
    color: #2196f3;
}

#slide2:hover {
    color: #2196f3;
}

.searchInput::placeholder {
    font-size: 18px;
}
</style>
